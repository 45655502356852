.particle--intro {
    position: absolute;
    height: 100vh;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: transparent;
    z-index: -1;
    opacity: 0.67;
}

.theHeader {
    z-index: 1;
}